import {Suspense, Fragment, lazy} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<SuspenseLoader/>}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <Component {...props} />
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        path: '/preview',
        guard: Authenticated,
        layout: SidebarLayout,
        routes: [
            {
                exact: true,
                path: '/preview/dashboards/finances',
                component: lazy(() => import('src/content/dashboards/Finance'))
            },
            {
                exact: true,
                path: '/preview/management/finances',
                component: () => (
                    <Redirect to="/preview/management/finances/list"/>
                )
            },
            {
                exact: true,
                path: '/preview/management/finances/list',
                component: lazy(() => import('src/content/management/Finances'))
            },
            {
                exact: true,
                path: '/preview/management/finances/create',
                component: lazy(() => import('src/content/management/Finances/create'))
            },
            {
                exact: true,
                path: '/preview/management/finances/:id',
                component: lazy(() => import('src/content/management/Finances/single'))
            },
            {
                exact: true,
                path: '/preview/management/projects',
                component: () => (
                    <Redirect to="/preview/management/projects/list"/>
                )
            },
            {
                exact: true,
                path: '/preview/management/projects/list',
                component: lazy(() => import('src/content/management/Projects'))
            },
            {
                exact: true,
                path: '/preview/management/projects/create',
                component: lazy(() => import('src/content/management/Projects/create'))
            },
            {
                exact: true,
                path: '/preview/management/users',
                component: () => (
                    <Redirect to="/preview/management/users/list"/>
                )
            },
            {
                exact: true,
                path: '/preview/management/users/list',
                component: lazy(() => import('src/content/management/Users'))
            },
            {
                exact: true,
                path: '/preview/management/users/create',
                component: lazy(() => import('src/content/management/Users/create'))
            },
            // {
            //     exact: true,
            //     path: '/preview/management/finances/:id',
            //     component: lazy(() => import('src/content/management/Finances/single'))
            // },
            {
                component: () => <Redirect to="/status/404"/>
            }
        ]
    },
    {
        exact: true,
        path: '/',
        component: () => <Redirect to="/preview/dashboards/finances"/>
    },
    {
        exact: true,
        guard: Guest,
        path: '/login',
        component: lazy(() => import('src/content/pages/Auth/Login/Basic'))
    },
    {
        exact: true,
        guard: Guest,
        path: '/register',
        component: lazy(() => import('src/content/pages/Auth/Register/Basic'))
    },
    {
        exact: true,
        path: '/recover-password',
        component: lazy(() => import('src/content/pages/Auth/RecoverPassword'))
    },
    {
        exact: true,
        path: '/status/404',
        component: lazy(() => import('src/content/pages/Status/Status404'))
    },
    {
        path: '*',
        layout: BaseLayout,
        routes: [
            {
                component: () => <Redirect to="/"/>
            }
        ]
    }
];

export default routes;
