import axios from 'axios';
import * as https from "https";

const axiosInt = axios.create({
    baseURL: "https://wood-eco-design.ru:8000/",
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});

axiosInt.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'There is an error!'
        )
);

export default axiosInt;
