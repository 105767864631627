import {ReactNode} from 'react';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyTwoTone';
import GavelTwoToneIcon from '@material-ui/icons/GavelTwoTone';
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';

export interface MenuItem {
    link?: string;
    icon?: ReactNode;
    badge?: string;
    items?: MenuItem[];
    name: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
}

const menuItems: MenuItems[] = [
    {
        heading: 'Меню',
        items: [
            {
                name: 'Финансы',
                icon: MonetizationOnTwoToneIcon,
                link: '/preview/dashboards/finances'
            },
            {
                name: 'Проекты',
                icon: BuildTwoToneIcon,
                link: '/preview/dashboards/projects'
            },
            {
                name: 'Пользователи',
                icon: AssignmentIndTwoToneIcon,
                link: '/preview/dashboards/users'
            }
        ]
    },
    {
        heading: 'Управление',
        items: [
            {
                name: 'Финансы',
                icon: AttachMoneyIcon,
                link: '/preview/management/finances',
                items: [
                    {
                        name: 'Список операций',
                        link: '/preview/management/finances/list'
                    },
                    {
                        name: 'Создание операции',
                        link: '/preview/management/finances/create'
                    }
                ]
            },
            {
                name: 'Проекты',
                icon: GavelTwoToneIcon,
                link: '/preview/management/projects',
                items: [
                    {
                        name: 'Список проектов',
                        link: '/preview/management/projects/list'
                    },
                    {
                        name: 'Создание проекта',
                        link: '/preview/management/projects/create'
                    }
                ]
            },
            {
                name: 'Пользователи',
                icon: GroupTwoToneIcon,
                link: '/preview/management/users',
                items: [
                    {
                        name: 'Список пользователей',
                        link: '/preview/management/users/list'
                    },
                    {
                        name: 'Создание пользователя',
                        link: '/preview/management/users/create'
                    }
                ]
            }
        ]
    }
];

export default menuItems;
